<template>
	<div class="payment-txn">
		<h3>Payment Transaction</h3>
		<div>
			<p>
				<strong>Receiver account</strong>
				<br />
				<input type="text" v-model="state.receiverAccountAddr" placeholder="Enter receiver address" style="max-width: 38em; width: 100%;" />
			</p>
			<p>
				<strong>Payment amount</strong>
				<br />
				<input type="text" v-model.number="state.paymentTxnAmount" placeholder="Payment amount" style="max-width: 3em; width: 100%; text-align: center;"/>
				ALGOS
			</p>
			<p>
				<strong>Payment note</strong>
				<br />
				<input type="text" v-model="state.paymentTxnNote" placeholder="Payment note" style="max-width: 46em; width: 100%;" />
			</p>
			<p>
				<a href="#" class="btn" @click.prevent="sendPaymentTxn">
					Send Algos to Receiver account
				</a>
			</p>
			<p class="error" v-if="state.errorMessage">
				{{ state.errorMessage }}
			</p>
			<p class="success" v-if="state.successMessage" v-html="state.successMessage"></p>
		</div>
	</div>
</template>

<script setup>
import { reactive, defineProps, defineEmits } from 'vue'

import useAlgorandClient from '../composables/algorand-client'
const algorandClient = useAlgorandClient()

const props = defineProps([
	'connector',
	'address',
	'algoExplorerUrl',
])

const emit = defineEmits([
	'fetching',
	'read-account-information',
])

const state = reactive({
	errorMessage: false,
	successMessage: false,
	paymentTxnAmount: 5,
	paymentTxnNote: "Thanks a bunch fruitHODLer, here's a little Algofam love to cover ongoing dev/hosting/Pinata costs.",
  receiverAccountAddr: process.env.VUE_APP_RECEIVER_ACCOUNT_ADDR ? process.env.VUE_APP_RECEIVER_ACCOUNT_ADDR : ""
})

function reset() {
	state.errorMessage = false
}

async function getTxnSuggestedParams() {
	emit('fetching', true)
	console.log('start: getTxnSuggestedParams')
	let txnSuggestedParams = await algorandClient.getTxnSuggestedParams()
	console.log('end: getTxnSuggestedParams')
	emit('fetching', false)
	return txnSuggestedParams
}

async function buildPaymentTxn(amount, noteStr) {
	const sender = props.address
	const receiver = state.receiverAccountAddr
	const enc = new TextEncoder()
	const note = enc.encode(noteStr)
	const suggestedParams = await getTxnSuggestedParams()
	let txn = algorandClient.buildPaymentTxn(amount, sender, receiver, note, suggestedParams)
	return txn
}

async function sendPaymentTxn() {
	console.log('start: sendPaymentTxn')
	state.errorMessage = false
	state.successMessage = false
	if (!state.paymentTxnAmount) {
		return state.errorMessage = "Payment amount must be set"
	}
	if (state.paymentTxnAmount <= 0) {
		return state.errorMessage = "Payment amount must be positive"
	}
	try {
		const txns = [
			{
				amount: state.paymentTxnAmount*1000000,
				note: state.paymentTxnNote,
			},
		]
		let unsignedPaymentTxns = []
		for(const txn of txns) {
			const unsignedPaymentTxn = await buildPaymentTxn(txn.amount, txn.note)
			unsignedPaymentTxns.push(unsignedPaymentTxn)
			console.log(`unsignedPaymentTxn:`, unsignedPaymentTxn)
		}

		// Sign the set of transactions
		emit('fetching', true, "Go to your wallet and sign the transaction...")
		console.log('start: signTxns')
		const signedTxns = await algorandClient.signTxns(props.connector, unsignedPaymentTxns)
		console.log('signedTxns', signedTxns)
		console.log('end: signTxns')
		emit('fetching', false)

		
		// Sending each signed txn one at a time
		let completedTxns = []
		for(const signedTxn of signedTxns) {
			emit('fetching', true, "Sending transaction")
			console.log('start: sendSignedTxn:')
			const completedTxn = await algorandClient.sendSignedTxn(signedTxn)
			completedTxns.push(completedTxn)
			console.log('completedTxn:', completedTxn)
			console.log('end: sendSignedTxn')
			emit('fetching', false)
		}
		// Success
		state.successMessage = `Your payment has been successful! <a href="${props.algoExplorerUrl}/tx/${completedTxns[0]}" target="_blank">View transaction on Algo Explorer</a>`
		// Reset
		reset()
		// Update balance
		emit('read-account-information')
	}
	catch (error) {
		console.log('sendPaymentTxn:', error)
		state.errorMessage = error.message
	}
	emit('fetching', false)

	console.log('end: sendPaymentTxn')
}
</script>

<style lang="scss" scoped>
h1 {
	text-align: center;
}
ul {
	padding-left: 1em;
}
.error {
	color: red;
}
.success {}
</style>
