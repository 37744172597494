<template>
	<div class="mint-nft">
		<h3>Mint NFT</h3>
		<div>
			<h4>Details</h4>
			<p><strong>Asset Name:</strong> <input type="text" v-model="state.assetName" maxlength="32" /></p>
			<p><strong>Unit Name:</strong> <input type="text" v-model="state.unitName" maxlength="8" /></p>
			<p><strong>Description:</strong> <input type="text" v-model="state.description" /></p>
			<!-- <p><strong>Asset URL:</strong> <input type="text" v-model="state.assetURL" /></p> -->
			<!-- <p><strong>External URL:</strong> <input type="text" v-model="state.external_url" /></p> -->
		</div>
		<div>
			<h4>Traits</h4>
			<div v-if="state.properties">
				<p v-for="(property, t) in state.properties" :key="t">
					Key: <input type="text" v-model="property.key" />
					Value: <input type="text" v-model="property.value" />
					<a href="#" @click.prevent="removeTrait(t)">(remove)</a>
				</p>
			</div>
			<p>
				<a href="#" @click.prevent="addTrait">Add</a>
			</p>
		</div>
		<div>
			<h4>Asset</h4>
			<p><strong>Image:</strong> <input type="file" @change="handleFileUpload( $event )"/></p>
		</div>
		<div>
			<h4>Ready...</h4>
			<p>
				<a href="#" @click.prevent="mint">Mint!</a>
				|
				<a href="#" class="reset" @click.prevent="reset">Reset</a>
			</p>
			<p class="error" v-if="state.errorMessage">
				{{ state.errorMessage }}
			</p>
			<p class="success" v-if="state.successMessage">
				{{ state.successMessage }}
			</p>
		</div>
		<div v-if="props.assets.length">
			<h3>Assets</h3>
			<div class="assets">
				<div v-for="asset in props.assets.slice().reverse()" :key="asset.index">
					<div>
						<h4>{{ asset.params.name }}</h4>
						<div>
							ID: {{ asset.index }}
							<br />
							Unit: {{ asset.params['unit-name'] }}
						</div>
						<div
							v-if="algorandClient.assetUrlToIpfsPublicGatewayUrl(asset.params.url)"
							class="image"
							:style="{ backgroundImage: `url('${algorandClient.assetUrlToIpfsPublicGatewayUrl(asset.params.url)}')` }"
						></div>
						<div
							v-if="!algorandClient.assetUrlToIpfsPublicGatewayUrl(asset.params.url)"
							class="no-image"
						></div>
						<div class="links">
							<a :href="`${peraExplorerUrl}/assets/${asset.index}`" target="_blank">View NFT on Pera Explorer</a>
							<br />
							<a :href="`${algoExplorerUrl}/asset/${asset.index}`" target="_blank">Inspect on Algo Explorer</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { reactive, defineProps, defineEmits } from 'vue'

import useAlgorandClient from '../composables/algorand-client'
const algorandClient = useAlgorandClient()

const props = defineProps([
	'connector',
	'address',
	'assets',
	'algoExplorerUrl',
	'peraExplorerUrl',
])

const emit = defineEmits([
	'fetching',
	'read-account-information',
])

const state = reactive({
	errorMessage: false,
	successMessage: false,
	unitName: "",
	assetName: "",
	assetURL: "",
	description: "",
	external_url: "https://fruithodler.com",
	properties: [
		{
			key: "",
			value: "",
		},
	],
	file: "",
})

function reset() {
	state.errorMessage = false
	state.unitName = ""
	state.assetName = ""
	state.assetURL = ""
	state.description = ""
	state.external_url = "https://fruithodler.com"
	state.properties = [
		{
			key: "",
			value: "",
		}
	]
	state.file = ""
}
async function mint() {
	state.errorMessage = false
	state.successMessage = false
	if (!state.assetName) {
		return state.errorMessage = "Asset Name must be set"
	}
	if (!state.unitName) {
		return state.errorMessage = "Unit Name must be set"
	}
	emit('fetching', true, "Go to your wallet and sign the transaction...")
	try {
		const unsignedNftTxn = await algorandClient.buildNftTxn(props.address, state.unitName, state.assetName, state.assetURL, state.description, state.external_url, state.properties, state.file)

		// Sign each txn (we just have one)
		const unsignedNftTxns = [unsignedNftTxn]
		console.log('start: signTxns')
		const signedNftTxns = await algorandClient.signTxns(props.connector, unsignedNftTxns)
		console.log('signedNftTxns', signedNftTxns)
		console.log('end: signTxns')

		// Sending each signed txn one at a time (we just have one)
		for(const signedNftTxn of signedNftTxns) {
			console.log('start: sendSignedNftTxn:')
			emit('fetching', true, "Minting NFT")
			const completedNftTxn = await algorandClient.sendSignedTxn(signedNftTxn)
			console.log('completedNftTxn:', completedNftTxn)
			console.log('end: sendSignedNftTxn')
		}
		// Success
		state.successMessage = "Your NFT has been minted!"
		// Reset
		reset()
		// Update balance
		emit('read-account-information')
	}
	catch (error) {
		console.log('Error mint:', error)
		state.errorMessage = error.message
	}
	emit('fetching', false)
}
function addTrait() {
	state.properties.push({
		key: "",
		value: "",
	})
}
function removeTrait(i) {
	if (state.properties.length <= 1) {
		return false
	}
	state.properties.splice(i,1)
}
function handleFileUpload(event){
	state.file = event.target.files[0]
}
</script>

<style lang="scss" scoped>
$black: #1E2120;
$white: #FFFFFF;
$green: #34FFB3;
$peach: #FF7E83;

a {
	&.reset {
		color: red;
	}
}
.error {
	color: red;
}
.success {}
.assets {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin-left: -.75em;
	margin-right: -.75em;
	> div {
		display: inline-block;
		flex: 0 0 290px;
		> div {
			margin: .75em;
			padding: .75em;
			background-color: $white;
			border: solid 1px $green;
			box-shadow: 0 0 10px $green;
			border-radius: 3px;
			color: $black;
			h4 {
				color: $black;
				margin: 0 0 .5em;
			}
			div.links {
				font-size: .8em;
				a {
					color: $black;
				}
			}
			div.image {
				padding-bottom: 100%;
				background-color: $white;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}
			div.no-image {
				padding-bottom: 100%;
				background-color: $green;
			}
		}
	}
}
</style>
