<template>
  <div class="wallet-connect">
    <LoadingSpinner v-if="state.fetching" :message="state.fetchingMessage" />
    <div class="header">
      <div class="logo">
        <h1 style="display: none;">FruitHODLer - Mint NFT</h1>
        <img src="@/assets/logo-text-white.svg" alt="FruitHODLer" />
      </div>
      <div class="connect">
        <a v-if="!state.address" href="#" @click.prevent="walletConnectInit">Connect to {{ networkPretty }}</a>
        <a v-else href="#" @click.prevent="killSession">Disconnect from {{ networkPretty }}</a>
      </div>
    </div>
    <div class="title"><span>Mint</span> NFT</div>
    <div class="welcome" v-if="!state.address">
      <div>
        <h2>Welcome</h2>
        <p style="min-height: 6.8em;">
          Mint NFT is a tool for minting NFTs on the <a href="https://www.algorand.foundation" target="_blank">Algorand</a> blockchain using <a href="https://www.pinata.cloud" target="_blank">Pinata</a> to serve content from IPFS. Connect your wallet to send NFTs to friends, family or worst enemies.
        </p>
        <p>
          <a href="#" class="btn" @click.prevent="walletConnectInit">Connect</a>
        </p>
      </div>
      <div>
        <h2 class="peach">About</h2>
        <p style="min-height: 6.8em;">
          Mint NFT is created by <a href="https://fruithodler.com" target="_blank">fruitHODLer</a>. Visit our website to find out more about our projects building on Algorand and the team behind it.
        </p>
        <p>
          <a href="https://fruithodler.com" target="_blank" class="btn btn-info">Visit fruitHODLer</a>
        </p>
      </div>
    </div>
    <div v-if="state.address">
      <h2>Account Address</h2>
      <div>
        <p>
          {{ state.address }}
        </p>
      </div>
    </div>
    <div v-if="state.address">
      <h2>Account Information</h2>
      <p>
        <a href="#" class="btn" @click.prevent="readAccountInformation">
          Get Account Balance
        </a>
      </p>
      <div v-if="state.accountInformation">
        <div v-if="state.accountInformation.amount">
          <h3>Balance</h3>
          <p>
            {{ algorandClient.microToAlgos(state.accountInformation.amount) }}
            ALGO
          </p>
        </div>
      </div>
    </div>
    <div v-if="state.accountInformation">
      <h2>Transactions</h2>
      <PaymentTxn
        :connector="state.connector"
        :address="state.address"
        :algo-explorer-url="algoExplorerUrl"
        :pera-explorer-url="peraExplorerUrl"
        @fetching="fetching"
        @read-account-information="readAccountInformation"
      />
    </div>
    <div v-if="state.accountInformation">
      <h2>NFTs</h2>
      <MintNft
        :connector="state.connector"
        :address="state.address"
        :assets="state.accountInformation['created-assets']"
        :algo-explorer-url="algoExplorerUrl"
        :pera-explorer-url="peraExplorerUrl"
        @fetching="fetching"
        @read-account-information="readAccountInformation"
      />
    </div>
    <div class="divider"></div>
    <div class="footer">
      <div class="logo">
        <p>Project grown by</p>
        <img src="@/assets/logo-text-white.svg" alt="FruitHODLer" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, computed } from 'vue'

import LoadingSpinner from './helpers/LoadingSpinner.vue'
import PaymentTxn from './PaymentTxn.vue'
import MintNft from './MintNft.vue'

import WalletConnect from "@walletconnect/client";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";

import useAlgorandClient from '../composables/algorand-client'
const algorandClient = useAlgorandClient()


const state = reactive({
  network: process.env.VUE_APP_NETWORK ? process.env.VUE_APP_NETWORK : 'testnet',
  fetching: false,
  fetchingMessage: "",
  connector: null,
  accounts: [],
  address: "",
  accountInformation: null,
})

const networkPretty = computed(()=>{
  return state.network.toUpperCase()
})
const algoExplorerUrl = computed(()=>{
  if (state.network == 'testnet') {
    return 'https://testnet.algoexplorer.io'
  }
  return 'https://algoexplorer.io'
})
const peraExplorerUrl = computed(()=>{
  if (state.network == 'testnet') {
    return 'https://testnet.explorer.perawallet.app'
  }
  return 'https://explorer.perawallet.app'
})

async function walletConnectInit() {
  // Create a connector
  state.connector = new WalletConnect({
    bridge: "https://bridge.walletconnect.org",
    qrcodeModal: QRCodeModal,
  })

  subscribeToEvents()

  // Check if connection is already established
  if (!state.connector.connected) {
    // create new session
    console.log('Create new session')
    await state.connector.createSession()
  }
  else {
    console.log('Retrieved existing session')
    onSessionUpdate(state.connector.accounts)
  }
}

function subscribeToEvents() {
  // Check if already subscribed
  if (!state.connector) {
    return
  }
  console.log('subscribeToEvents')
  // Subscribe to connection events
  state.connector.on("connect", (error, payload) => {
    console.log('onConnect')
    if (error) {
      throw error
    }
    console.log(payload)
    // Get provided accounts
    onConnect(payload.params[0].accounts)
  });

  state.connector.on("session_update", (error, payload) => {
    console.log('onSessionUpdate')
    if (error) {
      throw error
    }
    console.log(payload)
    // Get updated accounts 
    onSessionUpdate(payload.params[0].accounts)
  })

  state.connector.on("disconnect", (error, payload) => {
    console.log('onDisconnect')
    if (error) {
      throw error
    }
    console.log(payload)
    onDisconnect()
  })
}

function onConnect(accounts) {
  console.log('Connected')
  state.accounts = accounts
  state.address = accounts[0]
}

function onSessionUpdate(accounts) {
  console.log('SessionUpdated')
  state.accounts = accounts
  state.address = accounts[0]
}

function onDisconnect() {
  console.log('Disconnected')
  resetApp()
}

function killSession() {
  console.log('killSession')
  if (state.connector) {
    state.connector.killSession()
  }
}

function resetApp() {
  console.log('resetApp')
  state.fetching = false
  state.connector = null
  state.accounts = []
  state.address = ""
  state.accountInformation = null
}

function fetching(fetching, message = "") {
  state.fetching = fetching
  state.fetchingMessage = message
}

async function readAccountInformation() {
  fetching(true)
  console.log('start: readAccountInformation')
  state.accountInformation = await algorandClient.readAccountInformation(state.address)
  console.log('end: readAccountInformation')
  fetching(false)
}

onMounted(async () => {})
</script>

<style lang="scss" scoped></style>
