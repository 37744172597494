<template>
  <WalletConnect />
</template>

<script>
import WalletConnect from './components/WalletConnect.vue'

export default {
  name: 'App',
  components: {
    WalletConnect,
  }
}
</script>

<style lang="scss">
$black: #1E2120;
$white: #FFFFFF;
$green: #34FFB3;
$peach: #FF7E83;

.green {
  color: $green;
}
.peach {
  color: $peach;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  color: $white;
  background-color: $black;
}
h1, h2, h3, h4 {
  margin: 1em 0 .5em;
  line-height: 1.3em;
  color: $green;
}
h2 {
  font-size: 1.3em;
}
h3 {
  font-size: 1.2em;
}
h4 {
  font-size: 1.1em;
}
p,
ul,
ol {
  margin: 0 0 1em;
}
a {
  color: $green;
  text-decoration: underline;
  transition: opacity .3s;
  &:hover {
    opacity: .7;
  }
}
.btn {
  display: inline-block;
  padding: .5em 3em;
  font-weight: bold;
  text-decoration: none;
  color: $black;
  background-color: $green;
  border-radius: 3px;
  box-shadow: 0;
  transition: box-shadow .3s;
  &:hover {
    opacity: 1;
    box-shadow: 0 0 .5em $green;
  }
  &.btn-info {
    background-color: $peach;
    &:hover {
      box-shadow: 0 0 .5em $peach;
    }
  }
}
input[type="text"] {
  padding: .5em 1em;
  font-size: inherit;
}
.walletconnect-qrcode__base {
  background: rgba($black, .9) !important;
}
#app {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
  .header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 3em 0 3.5em;
    > div.logo {
      img {
        height: 1em;
        width: auto;
      }
    }
    > div.connect {}
  }
  .footer {
    margin: 6em auto;
    > div.logo {
      text-align: center;
      img {
        height: 1em;
        width: auto;
      }
    }
  }
  .divider {
    border: 1px dashed rgba($white, .4);
    margin: 3.6em 0;
  }
  .title {
    text-align: center;
    font-size: 12em;
    font-weight: bold;
    color: $white;
    text-shadow: 0px 0px 20px $white;
    span {
      color: $green;
      text-shadow: 0px 0px 20px $green;
    }
  }
  .welcome {
    display: flex;
    flex-flow: row nowrap;
    > div {
      flex: 0 0 50%;
    }
    p {
      font-weight: bold;
    }
  }
}
@media only screen and (max-width: 640px) {
  body {
    font-size: 14px;
  }
  #app {
    .title {
      font-size: 5em;
    }
    .welcome {
      display: block;
    }
  }
}
</style>
