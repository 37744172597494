const algosdk = require('algosdk')
import { formatJsonRpcRequest } from "@json-rpc-tools/utils"

import axios from 'axios'

// Set base url for the fruitHODLer server
axios.defaults.baseURL = `${process.env.VUE_APP_SERVER_HOST}${process.env.VUE_APP_SERVER_PORT ? ':'+process.env.VUE_APP_SERVER_PORT : ''}`

// Allow cross origin cookies to be set (ensure App is whitelisted for CORS)
axios.defaults.withCredentials = true
// Get CSRF token for the session and send with subsequent requests
axios.get('getcsrftoken').then((response) => {
  axios.defaults.headers.common['CSRF-Token'] = response.data.csrfToken
}, (error) => {
  console.log(error)
})

export default function useAlgorandClient() {
  function microToAlgos(number) {
    let microAlgos = parseInt(number)
    return (microAlgos / 1000000).toFixed(3)
  }

  function generateAccount() {
    try {
      const account = algosdk.generateAccount()
      console.log("Account Address = " + account.addr)
      let accountMnemonic = algosdk.secretKeyToMnemonic(account.sk)
      console.log("Account Mnemonic = "+ accountMnemonic)
      account.mnemonic = accountMnemonic

      console.log("Account created. Save off Mnemonic and address")
      console.log("Add funds to account using the TestNet Dispenser: ")
      console.log("https://dispenser.testnet.aws.algodev.network/ ")
      console.log(account)
      return account
    }
    catch (err) {
      console.error("err", err)
      return false
    }
  }

  /**
   * Compute IPFS asset url into an IPFS public gateway url.
   *
   * @param assetUrl String e.g. "ipfs://XXXXXXXXXXXXXXXXXXXXXXX"
   *
   * @return String|null (Returns the url or null if not an IPFS url)
   */
  function assetUrlToIpfsPublicGatewayUrl(assetUrl) {
    const desiredGatewayPrefix = "https://ipfs.io"
    if (assetUrl.indexOf('ipfs://') == -1) {
      return null
    }
    else {
      return `${desiredGatewayPrefix}/${assetUrl.replace('ipfs://', 'ipfs/')}`
    }
  }

  async function readAccountInformation(accountAddr) {
    try {
      let payload = {
        accountAddr: accountAddr
      }
      let response = await axios.post('read-account-information', payload)
      return response.data
    }
    catch (error) {
      console.error('Error readAccountInformation:', error)
    }
  }

  /**
   * Get transactions params.
   *
   * @return Object
   */
  async function getTxnSuggestedParams() {
    try {
      let response = await axios.get('get-txn-suggested-params')
      return response.data
    }
    catch (error) {
      console.error('Error getTxnSuggestedParams:', error)
    }
  }

  /**
   * Sign txns.
   *
   * @param connector WalletConnect (instance of connected session)
   * @param txns Array (of unsigned transactions)
   *
   * @return Array<String|null> (Array of Signed transactions encoded as base64 string)
   */
  async function signTxns(connector, txns) {
    // Sign transaction
    // txns is an array of algosdk.Transaction like below
    // i.e txns = [txn, ...someotherTxns], but we've only built one transaction in our case
    const txnsToSign = txns.map(txn => {
      const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString("base64")
      return {
        txn: encodedTxn,
        message: 'Please check and sign this transaction.',
        // Note: if the transaction does not need to be signed (because it's part of an atomic group
        // that will be signed by another party), specify an empty singers array like so:
        // signers: [],
      }
    })
    // Sign the txns using WalletConnect
    const requestParams = [txnsToSign]
    const request = formatJsonRpcRequest("algo_signTxn", requestParams)
    try {
      const result = await connector.sendCustomRequest(request)
      // console.log(result)
      return result

      /*const decodedResult = result.map(element => {
        return element ? new Uint8Array(Buffer.from(element, "base64")) : null
      })
      return decodedResult*/
    }
    catch (error) {
        console.error('Error signTxns:', error)
    }
  }

  /**
   * Send the signed transaction.
   *
   * @param txn String (Signed transactions encoded as base64 string)
   *
   * @return Object (completed txn)
   */
  async function sendSignedTxn(txn) {
    try {
      let payload = {
        txn: txn
      }
      let response = await axios.post('send-signed-txn', payload)
      return response.data
    }
    catch (error) {
      console.error('Error sendSignedTxn:', error)
      throw new Error(error)
    }
  }

  /**
   * Build unsigned payment txn.
   *
   * @param amount Number (e.g. 1000000 microAlogs (1 Algo))
   * @param sender String
   * @param receiver String
   * @param note String
   * @param suggestedParams Object (see getTxnSuggestedParams fn)
   *
   * @return Object (Unsigned transaction)
   */
  function buildPaymentTxn(amount, sender, receiver, note, suggestedParams) {
    const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from: sender,
        to: receiver,
        amount: amount,
        note: note,
        suggestedParams: suggestedParams
    })
    return txn
  }

  /**
   * Get the unsigned NFT ASA transaction params, upload the file to IPFS and then return a ASA transaction.
   *
   * @param (from, unitName, assetName, assetURL, description, external_url, properties, file)
   *
   * @return Object (Unsigned ASA transaction)
   */
  async function buildNftTxn(from, unitName, assetName, assetURL, description, external_url, properties, file) {
    let formData = new FormData()
    formData.append('from', from)
    formData.append('unitName', unitName)
    formData.append('assetName', assetName)
    formData.append('assetURL', assetURL)
    formData.append('description', description)
    formData.append('external_url', external_url)
    formData.append('properties', JSON.stringify(properties))
    formData.append('file', file)
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    let unsignedNftTxnParams = null
    try {
      let response = await axios.post('build-nft-txn-params', formData, options)
      unsignedNftTxnParams = response.data
    }
    catch (error) {
      console.error("Error buildNftTxnParams:", error)
      throw new Error(error.response.data)
    }
    // Uint8Array get screwed and encoded as Object when returned from the server therefore re-encode them
    unsignedNftTxnParams.assetMetadataHash = new Uint8Array(Object.values(unsignedNftTxnParams.assetMetadataHash))
    unsignedNftTxnParams.note = new Uint8Array(Object.values(unsignedNftTxnParams.note))
    // Build the ASA txn
    return await algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject(unsignedNftTxnParams)
  }

  return {
    microToAlgos,
    generateAccount,
    assetUrlToIpfsPublicGatewayUrl,
    readAccountInformation,
    getTxnSuggestedParams,
    signTxns,
    sendSignedTxn,
    buildPaymentTxn,
    buildNftTxn,
  }
}
